/*
.containerEnviado{
    min-width: 100vw;
    min-height: 100vh;
    background-image: linear-gradient(to bottom , #FFF 0%,#F8EEE1 5%,#F8EEE1 30%, #C3A87F 100%);


    .headerEnviado{
        min-width: 100vw;
        min-height: 20vh;
        display: flex;
        flex-direction: row;

        .logoEnviado{
            min-width: 20vw;
            min-height: 15vh;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                
                min-width: 20vw;
            }
        }
        .textHeader{
            min-width: 60vw;
            max-width: 60vw;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 2rem;
            min-height: 15vh;
            color: #AF8E5B;
            font-family: Gabi;
        }
    }
    .imagenEnviado{
        
        min-height: 45vh;
        min-width: 100vw;
        background-image: url('../../img/fondoDesvanecido.svg');
    }
    .textEnviado{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 90vw;
        min-height: 10vh;
        label{
            font-size: 1.2rem;
            font-family: Avenir;
            font-style: oblique;
            font-weight: 400;
            min-width: 100%;
            text-align: center;
        }
    }
    .hablarEspecialista{
        min-width: 90vw;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 15vh;
        a{
            text-decoration: none;
        }
        button{
            min-width: 90vw;
            min-height: 8vh;
            border-radius: 2vw;
            border-color: #FFF;
            background-color: #AF8E5B;
            font-size: 1.5rem;
            color: #FFF;
            font-family: Avenir;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            label{
                min-height: 5vh;
                line-height: 5vh;
                margin-left: 0.5rem;
                font-size: 1.2rem;
                letter-spacing: 0.05rem;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    }


    .footer{
        min-width: 90vw;
        display: flex;
        flex-direction: column;
        div{
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #FFF;
            justify-content: center;
            align-items: center;
            label{
                min-width: 95vw;
                max-width: 95vw;          
                font-size: .9rem;        
                font-family: Avenir;
                font-weight: 600;
                line-height: 1.5rem;
                a{
                    color: #FFF;
                }
            }
            img{
                margin-top: 5vh;
                margin-bottom: 5vh;
                max-width: 50vw;
            }
        }
    }
}







@media (min-width:1000px){

    .containerEnviado{
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        background-image: linear-gradient(to bottom , #FFF 0%,#F8EEE1 5%,#F8EEE1 30%, #C3A87F 100%);
        margin: 0;
    
        .headerEnviado{
         
            z-index: 1;
            background-color: rgba(195,168,127,0.3);
            backdrop-filter: blur(25px);
            min-height: 100vh;
            position: absolute;
            min-width: 40vw;
            max-width: 40vw;            
            margin-left: 60vw;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .logoEnviado{
                min-width: 30vw;
                max-width: 30vw;
                min-height: 20vh;
                display: flex;
               margin-bottom: 80vh;
                justify-content: center;
                background-image: url('../../img/logoFooterSVG.svg');
                background-size: 50%;
                            
                background-position: center;
                background-position-y: 10vh;
                background-repeat: no-repeat;
                align-items: center;
                img{

                    display: none;
                    min-width: 20vw;
                }
            }
            .textHeader{
                position: absolute;
                z-index: 4;
                margin-top: -10vh;
                min-width: 20vw;
                max-width: 20vw;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 4rem;
                min-height: 30vh;
                color: #AF8E5B;
                font-family: Gabi;
            }
        }
        .imagenEnviado{
            position: absolute;
            min-height: 100vh;
            min-width: 70vw;
            max-width: 70vw;
            background-repeat: no-repeat;
            background-image: url('../../img/fondoDesvanecido.svg');
            background-size: 120%;
        }
        .textEnviado{
            z-index: 2;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            min-height: 20vh;
            margin-top: 70vh;
            margin-left: 60vw;
            font-weight: 400;
            label{
                font-size: 1.2rem;
                font-family: Avenir;
                font-style: oblique;
                font-weight: 400;
                min-width: 30vw;
                max-width: 30vw;
                text-align: center;
                color: #4C4842;
            }
        }
        .hablarEspecialista{
            z-index: 3;
            position: absolute;
            min-width: 40vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 60vw;
            margin-top: 80vh;
            min-height: 15vh;
            a{
                text-decoration: none;
            }
            button{
            
                min-width: 20vw;
                max-width: 20vw;
                min-height: 5vh;
                border-radius: 0.54vw;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1.5rem;
                color: #FFF;
                font-family: Avenir;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0;
                label{
                    min-height: 2vh;
                    line-height: 2vh;
                    margin-left: 0.5rem;
                    font-size: 1.2rem;
                    letter-spacing: 0.05rem;
                    font-family: Avenir;
                    font-weight: 600;
                }
            }
        }
    
    
        .footer{
            position: absolute;
            min-width: 60vw;
            max-width: 60vw;
            margin-top: 90vh;
            display: flex;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 95vw;
                    max-width: 95vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    display: none;
                   // margin-top: 5vh;
                    margin-bottom: 5vh;
                    min-width: 20vw;
                    max-width: 50vw;
                }
            }
        }
    }


}






*/











/* RESOLUCIONES  MOBILE */

@media (min-width:360px) and (min-height: 640px)  {
    .containerEnviado{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom , #FFF 0%,#F8EEE1 5%,#F8EEE1 30%, #C3A87F 100%);
    
  
        .headerEnviado{
            min-width: 100vw;
            max-width: 100vw;
            min-height: 20vh;
            display: flex;
            flex-direction: row;
    
            .logoEnviado{
                min-width: 20vw;
                min-height: 15vh;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    position: absolute;
                    min-width: 10vw;
                }
            }
            .textHeader{
                min-width: 60vw;
                max-width: 60vw;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 1.8rem;
                min-height: 15vh;
                color: #AF8E5B;
                font-family: Gabi;
            }
        }
        .imagenEnviado{
            
            min-height: 50vh;
            min-width: 100vw;
            background-image: url('../../img/fondoDesvanecido.svg');
            background-size: 130%;
            background-repeat: no-repeat;
            background-position: center;
            background-position-x: 0vw;        
        }
        .textEnviado{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 90vw;
            min-height: 10vh;
            label{
                font-size: 1rem;
                font-family: Avenir;
                font-style: oblique;
                font-weight: 400;
                min-width: 100%;
                text-align: center;
                color: #4C4842;
            }
        }
        .hablarEspecialista{
            display: none;
            min-width: 90vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 12vh;
            a{
                text-decoration: none;            
            }
            button{
                min-width: 80vw;
                min-height: 8vh;
                border-radius: 2vw;
                border-color: #FFF;
                background-color: #AF8E5B;
                font-size: 0.5rem;
                color: #FFF;
                font-family: Avenir;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: none;
                border: 1px solid #FFF;
                label{
                    min-height: 5vh;
                    line-height: 5vh;
                    margin-left: 0.5rem;
                    font-size: 1rem;
                    letter-spacing: 0.05rem;
                    font-family: Avenir;
                    font-weight: 600;
                }
            }
        }
        .containerBotons{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .containerLogoWeb{
                margin-top: 1vh;
                min-height: 5vh;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                min-width: 50vw;
                color: #906B33;
                img{
                    margin-top: 0.5vh;
                    max-width: 10vw;
                }
                label{
                    text-align: center;
                    max-width: 60vw;
                    color: #906B33;
                    font-size: 1rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1rem;
                    font-family: Avenir;
                }
            }

        }
       
        .footer{
            margin-top: 2vh;
            min-width: 90vw;
            display: flex;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 95vw;
                    max-width: 95vw;          
                    font-size: .8rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1rem;
                    a{
                        color: #906B33;
                    }
                }
                img{
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
    }
    
    
    

}

@media (min-width:360px) and (min-height: 780px)  {
    
}

@media (min-width:360px) and (min-height: 800px)  {

}

@media (min-width:390px) and (min-height: 844px)  {

}

@media (min-width:393px) and (min-height: 873px)  {

}

@media (min-width:412px) and (min-height: 915px)  {

}

@media (min-width:414px) and (min-height: 896px)  {

}

















/* RESOLUCIONES  DESKTOP */
@media (min-width:1280px) and (min-height: 720px)  {
    .containerEnviado{
        min-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        background-image: linear-gradient(to bottom , #FFF 0%,#F8EEE1 5%,#F8EEE1 30%, #C3A87F 100%);
        margin: 0;
    
        .headerEnviado{
         
            z-index: 1;
            background-color: rgba(195,168,127,0.3);
            backdrop-filter: blur(25px);
            min-height: 100vh;
            position: absolute;
            min-width: 40vw;
            max-width: 40vw;            
            margin-left: 60vw;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .logoEnviado{
                min-width: 30vw;
                max-width: 30vw;
                min-height: 20vh;
                display: flex;
               margin-bottom: 80vh;
                justify-content: center;
                background-image: url('../../img/logoFooterSVG.svg');
                background-size: 50%;
                            
                background-position: center;
                background-position-y: 10vh;
                background-repeat: no-repeat;
                align-items: center;
                img{

                    display: none;
                    min-width: 20vw;
                }
            }
            .textHeader{
                position: absolute;
                z-index: 4;
                margin-top: -25vh;
                min-width: 30vw;
                max-width: 30vw;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 3rem;
                min-height: 30vh;
                color: #AF8E5B;
                font-family: Gabi;
            }
        }
        .imagenEnviado{
            position: absolute;
            min-height: 100vh;
            min-width: 70vw;
            max-width: 70vw;
            background-repeat: no-repeat;
            background-image: url('../../img/fondoDesvanecido.svg');
            background-size: 120%;
        }
        .textEnviado{
            z-index: 2;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            min-height: 20vh;
            margin-top: 50vh;
            margin-left: 60vw;
            font-weight: 400;
            label{
                font-size: 1.2rem;
                font-family: Avenir;
                font-style: oblique;
                font-weight: 400;
                min-width: 30vw;
                max-width: 30vw;
                text-align: center;
                color: #4C4842;
            }
        }
        .hablarEspecialista{
            z-index: 3;
            position: absolute;
            min-width: 40vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 60vw;
            margin-top:62vh;
            min-height: 15vh;
            a{
                text-decoration: none;
            }
            button{        
                min-width: 22vw;
                max-width: 22vw;
                min-height: 8vh;
                border-radius: 0.54vw;
                border-color: #FFF;
                background-color: #906D37;
                font-size: 1.5rem;
                color: #FFF;
                font-family: Avenir;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0;
                label{
                    min-height: 2vh;
                    line-height: 2vh;
                    margin-left: 0.5rem;
                    font-size: 1.2rem;
                    letter-spacing: 0.05rem;
                    font-family: Avenir;
                    font-weight: 600;
                }
            }
        }
        .containerBotons{
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-left: 70vw;
            margin-top: 87vh;
            z-index: 20;
            .containerLogoWeb{
                margin-top: 1vh;
                min-height: 5vh;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                min-width: 20vw;
                color: #906B33;
                img{
                    margin-top: 0.5vh;
                    max-width: 2vw;
                }
                label{
                    text-align: center;
                    max-width: 60vw;
                    color: #906B33;
                    font-size: 1rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1rem;
                    font-family: Avenir;
                }
            }

        }
    
        .footer{
            position: absolute;
            min-width: 60vw;
            max-width: 60vw;
            margin-top: 90vh;
            display: flex;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: row;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 27vw;
                    max-width: 27vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;                    
                        text-align: center;   
     //                   color: #906B33;


                    }
                }
                img{
                    display: none;
                   // margin-top: 5vh;
                    margin-bottom: 5vh;
                    min-width: 20vw;
                    max-width: 50vw;
                }
                .visita{
                    text-align: right;
                    min-width: 20vw;
                }
            }
        }
    }
}

@media (min-width:1366px) and (min-height: 768px)  {
    
    
}

@media (min-width:1440px) and (min-height: 900px)  {
   
}

@media (min-width:1536px) and (min-height: 864px)  {
 
}

@media (min-width:1600px) and (min-height: 900px)  {

}

@media (min-width:1920px) and (min-height: 1080px)  {

}



