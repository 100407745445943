.generalContainerLanding{
    min-width: 100vw;
    min-height: 100vh;
    background-image: linear-gradient(to bottom right, #B59E8F, #AF8E5B);



    .sectionOne{
        min-height: 67vh;
        background-image: url('../../img/fondo.png');
        background-size: cover;
        background-size: 170%;
        

        background-position: center;
        
        background-position-x: -30vw;
        background-position-y: 0vh;
        background-repeat: no-repeat;


        .header{
            display: flex;
            .footersectionOne{
                min-width: 80vw;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                label{
                    min-width: 75vw;
                    max-width: 75vw;
                }
                .texone{
                    margin-top: 2vh;
                    font-size: 1.7rem;
                    color: #FFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 12vh;
                    font-family: Gabi;
                }
                .textwo{
                    color: #fff;
                    font-size: 1.3rem;
                    min-height: 8vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: Avenir;
                    font-style: oblique;
                }
            }
            .footersectionTwo{
                min-width: 20vw;
                min-height: 10vh;
                max-height: 10vh;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    min-width: 10vw;
                }
            }
        }
        .pointsSelection{
            min-height: 42vh;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10vh;
            div{
                color: #FFF;
                border: 1px solid #FFF;
                min-width: 1.8rem;
                max-width: 1.8rem;
                min-height: 1.8rem;
                max-height: 1.8rem;
                border-radius: 1rem;
                font-size: 1.2rem;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                font-family: Avenir;
                opacity: 0.6;
            }
            .pointOne{
                margin-bottom: 18vh;
                margin-right: 12vw;
            }
            .pointTwo{
                margin-bottom: 5vh;
                margin-right: 50vw;
            }
            .pointThree{
                margin-bottom: 2vh;
                margin-right: 10vw;
            }
            .pointFour{
                margin-bottom: -16vh;
                margin-left: 52vw;
            }
            .pointFive{
                margin-bottom: -15vh;
                margin-right: 70vw;
            }
            .pointSix{
                margin-bottom: -15vh;
                margin-right: 0vw;
            }
            .pointSeven{
                margin-bottom: -22vh;
                margin-right: 42vw;
            }
        }
        .selected{
            background-color: #8E7144;
        }
        .optionselected{
            min-height: 8vh;
            background-color: rgba(255,255,255,0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            letter-spacing: 0.2rem;
            color: #8E7144;
            font-weight: 600;
            font-family: Avenir;
        }
    }


    .sectionCard{
        margin-top: -2vh;
        min-height: 10vh;
        background-color: #8E7144;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        text-align: center;
        color: #FFF;
        font-family: Gabi;
        font-style: oblique;
    }


    .sectionForm{
        form{
            div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 10vh;
                font-family: Avenir;
                input{
                    color: #FFF;
                    min-width: 85vw;
                    border-radius: 0.5rem;
                    min-height: 4vh;
                    background-color: transparent;
                    border: none;
                    border: 1px solid rgba(255,255,255, 0.5);
                    color: #FFF;
                    font-size: 1.2rem;
                    text-align: center;
                    font-family: Avenir;
                    font-weight: 600;
                }
                label{
                    font-size: 1rem;
                    color:#FFF;
                    letter-spacing: 0.2rem;
                    margin-top: 0.5rem;
                }
                input::placeholder{
                    color: #FFF;
                }
            }

            .agendacita{
                display: flex;
                min-height: 10vh;
                justify-content: center;
                flex-direction: row;
                align-items: center;
                font-size: 1.2rem;
                color: #FFF;
                label{
                    min-height: 6vh;
                    font-family: Avenir;
                    font-size: 1.3rem;

                }
                hr{
                    min-width: 15vw;
                }
            }
        }

    }
   
    .giftcard{
        min-width: 90vw;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 18vh;
        button{
            min-width: 90vw;
            min-height: 8vh;
            border-radius: 2vw;
            border-color: #FFF;
            background-color: #906D37;
            font-size: 1.5rem;
            color: #FFF;
            font-family: Avenir;
            font-weight: 600;
        }
    }

    .footer{
        min-width: 90vw;
        display: flex;
        flex-direction: column;
        div{
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #FFF;
            justify-content: center;
            align-items: center;
            label{
                min-width: 95vw;
                max-width: 95vw;          
                font-size: .9rem;        
                font-family: Avenir;
                font-weight: 600;
                line-height: 1.5rem;
                a{
                    color: #FFF;
                }
            }
            img{
                margin-top: 5vh;
                margin-bottom: 5vh;
                max-width: 50vw;
            }
        }
    }
    
}